<template>
    <main>
        <div class="content_1">
            <div class="main_container_1">
                <div class="poster">
                    <img src="@/assets/unnamed.jpg">
                </div>
                <div class="content_gap"></div>
                <div class="textbox">
                    <div class="textframe">
                        <h1>CAREER</h1>
                        <p class="clamp_text">
                            Zhang has worked as an acting coach for films and TV series, including the first season of the sitcom Cheng Long Guai Xu (2005), where he also played his first leading role, and the film Love on Credit (2011).
                            <br><br>
                            In 2008, Zhang left his teaching position at Beijing Film Academy to establish his own acting studio.
                            <br><br>
                            In 2009, Zhang collaborated with director Lou Ye for the first time in Spring Fever (2009), which won the prize for best screenplay at the 62nd Cannes Film Festival. Zhang later starred in the director's The Shadow Play (2018) and Saturday Fiction (2019), both of which received critical acclaim.
                            <br><br>
                            In 2017, Zhang appeared in End of Summer (2017), which won the KNN Award at the 22nd Busan International Film Festival.
                            <br><br>
                            In 2020, Zhang appeared in the web series The Bad Kids, based on the Zi Jinchen's novel, Bad Kid.
                            <br><br>
                            In 2023, Zhang gained widespread popularity for his role as a fishmonger-turned-crime-boss in the TV series The Knockout (2023).
                        </p>
                    </div>   
                </div>
            </div>
            
        </div>
    </main>
</template>

<script>
export default {
  name: 'CareerMain'
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
}

main{
    background-color: #5E5E5E;
}

@media (max-width:600px) {
    main{
        height: 1000px;
    }
}

.content_1{
    margin-left: 120px;
    margin-right: 120px;  
}

@media (max-width:600px) {
    .content_1{
        margin-left: 20px;
        margin-right: 20px;
    }
}

.main_container_1{
    display: grid;
    grid-template-columns: 40% 5% 50% 5%;
    height: 100%;
    width: 100%;
}

@media(max-width:600px){
    .main_container_1{
        padding-top: 20px;
        display: block;
    }
}

.content_1 img{
    height: 500px;
    width: auto;
    object-fit: cover;
}

@media(max-width:600px){
    .content_1 img{
        height: 300px;
    }
}

.content_1 .poster{
    margin-left: 150px;
    margin-right: 40px;
    overflow: hidden;
}

@media(max-width:600px){
    .content_1 .poster{
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
    }
}

.content_1 .textbox{
    position: relative;
    margin-left: 25px;
    margin-right: 25px;
    height: 320px;
    z-index: 1;
}

@media(max-width:600px){
    .content_1 .textbox{
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }
}

.content_1 .textbox .textframe {
    margin-left: 25px;
    margin-right: 25px;
    height: 50%;
    color: white;

}

@media(max-width:600px){
    .content_1 .textbox .textframe{
        margin-left: 0px;
        margin-right: 0px;
    }

    h1{
        text-align: center;
    }
}

h1{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;
}

p{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
}
</style>