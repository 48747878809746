<template>
    <main>
        <div class="content_1">
            <div class="main_container_1">

                <div class="poster">
                    <router-link to="/info"><img src="@/assets/797.jpg" class="poster_image"></router-link>
                </div>

                <div class="textbox">
                    <div class="textframe">
                        <h1>INFO</h1>
                        <p class="clamp_text"><br>Zhang Songwen was born in Shaoguan, Guangdong province. After high school, he held various jobs, including working at a printing factory, a food stall, and a beverage factory. From 1994 to 1999, he was employed as a tour guide with the China Travel Service. At the age of 24, Zhang first heard about Beijing Film Academy through a fellow tour guide. To pursue his dream of acting in films, he quit his job, moved to Beijing, and enrolled in the advanced vocational class of the Performance Department at Beijing Film Academy, where he served as class monitor and student union president. After graduating, he worked as a teaching assistant at the academy for eight years.</p>
                    </div>
                    <div class="textbox_button">
                        <router-link class="style_button" to="/info">More Info</router-link>
                    </div> 
                </div>
                
                <div class="content_gap"></div>

                <div class="poster">
                    <router-link to="/career"><img src="@/assets/unnamed.jpg" class="poster_image"></router-link>
                </div>

                <div class="textbox">
                    <div class="textframe">
                        <h1>CAREER</h1>
                        <p class="clamp_text"><br>Zhang has worked as an acting coach for films and TV series, including the first season of the sitcom Cheng Long Guai Xu (2005), where he also played his first leading role, and the film Love on Credit (2011).

                        In 2008, Zhang left his teaching position at Beijing Film Academy to establish his own acting studio.
                        
                        In 2009, Zhang collaborated with director Lou Ye for the first time in Spring Fever (2009), which won the prize for best screenplay at the 62nd Cannes Film Festival. Zhang later starred in the director's The Shadow Play (2018) and Saturday Fiction (2019), both of which received critical acclaim.
                        
                        In 2017, Zhang appeared in End of Summer (2017), which won the KNN Award at the 22nd Busan International Film Festival.
                        
                        In 2020, Zhang appeared in the web series The Bad Kids, based on the Zi Jinchen's novel, Bad Kid.
                        
                        In 2023, Zhang gained widespread popularity for his role as a fishmonger-turned-crime-boss in the TV series The Knockout (2023).</p>
                    </div>
                    <div class="textbox_button">
                        <router-link class="style_button" to="/career">More Info</router-link>
                    </div>
                </div>

                <div class="poster">
                    <router-link to="/filmography"><img src="@/assets/5511.jpg" class="poster_image"></router-link> 
                </div>

                <div class="textbox">
                    <div class="textframe">
                        <h1>FILMOGRAPHY</h1>
                        <p><br>Click the button below to see the specific page.</p>
                    </div>
                    <div class="textbox_button">
                        <router-link class="style_button" to="/filmography">More Info</router-link>
                    </div>
                </div>

                <div class="content_gap"></div>
                
                <div class="poster">
                    <router-link to="/gallery"><img src="@/assets/5522.jpeg" class="poster_image"></router-link> 
                </div>
                <div class="textbox">
                    <div class="textframe">
                        <h1>GALLERY</h1>
                        <p><br>Click the button below to see the specific page.</p>
                    </div>
                    <div class="textbox_button">
                        <router-link class="style_button" to="/gallery">More Info</router-link>
                    </div>
                </div>
            </div>
            
        </div>
    </main>
</template>

<script>
export default {
  name: 'IndexMain'
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
}

main{
    background-color: #5E5E5E;
}

.content_1{
    margin-left: 120px;
    margin-right: 120px;   
}

.main_container_1{
    display: grid;
    grid-template-columns: 16% 32% 4% 16% 32%;
    height: 100%;
    width: 100%;
}

@media (max-width: 600px) {
    .content_1{
        margin-left: 20px;
        margin-right: 30px;
    }

    .main_container_1{
        height: auto;
        width: 100%;
        grid-template-columns: 30% 70%;
    }

    .content_gap{
        display: none;
    }
}

.content_1 img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.content_1 .poster{
    background-color: darkgrey;
    height: 320px;
    overflow: hidden;
    margin-bottom: 20px;
}

.poster .poster_image{
    transition: transform 0.3s ease, filter 0.3s ease;
}

.poster:hover .poster_image{
    cursor: pointer;
    transform: scale(1.1);
    filter: brightness(75%) grayscale(75%);
}

.content_1 .textbox{
    position: relative;
    margin-left: 25px;
    margin-right: 25px;
    border: 1px solid rgb(150, 150, 150);
    width: 100%;
    height: 320px;
    z-index: 1;
}

@media (max-width: 600px) {
    .content_1 .poster{
        margin-top: 5px;
        margin-bottom: 2px;
        height: 150px;
    }
    .content_1 .textbox{
        margin-top: 5px;
        margin-bottom: 2px;
        margin-left: 15px;
        margin-right: 15px;
        height: 150px;
    }
}

.content_1 .textbox::before{
    content:"";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: url('@/assets/p288713428033.jpg');
    opacity: 0.1;
    background-size: cover;
    z-index: -1;
}

.content_1 .textbox .textframe {
    margin-top:20px;
    margin-left: 25px;
    margin-right: 25px;
    height: 50%;
    color: white;
}
@media (max-width: 600px){
    .content_1 .textbox .textframe {
        margin-top: 10px;
        margin-left: 15px;
        margin-right: 35px;
    }
}

.content_1 .textbox .textbox_button {
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 25px;
    color: white;

    width: 100px;
    height: 25px;
    background-color: #cc9933;
    border-radius: 4px;
    display: flex;
    text-align: center;
    justify-content: center;
}

@media (max-width: 600px) {
    .content_1 .textbox .textbox_button{
        margin-left: 15px; 
    }
}

.content_1 .textbox .textframe .clamp_text{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (max-width: 600px){
    .content_1 .textbox .textframe .clamp_text{
        -webkit-line-clamp: 4;
    }
}


/*
.content_1 .textbox .textbox_button .style_button{
    background-color: #cc9933;
    border:none;
    color: white;
    text-align: center;
    font-size: 14px;
    width: 100px;
    height: 25px;
    border-radius: 4px;
}
*/

.content_1 .textbox .textbox_button a {
    border:none;
    color: white;
    text-align: center;
    justify-content: center;
    line-height: 25px;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
}

.content_1 .textbox .textbox_button:hover{
    background-color: #b9a379;
    cursor: pointer;
}

h1{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;
}

p{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
}

@media (max-width:600px) {
    h1{font-size: 18px;}
    p{font-size: 9px;}
}
</style>