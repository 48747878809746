<template>
    <main>
            <div class="film_sort">
                <label for="sortSelect">Sort by:</label>
                <select id="sortSelect" class="custom_select" @change="handleSort">
                    <option value="default">Select Sorting</option>
                    <option value="alphabetical">Alphabetical</option>
                    <option value="year">Year</option>
                </select>
            </div>
            
            <div class="filmography">
                <div class="filmography_container" id="filmlist">
                    <div class="filmography_for" v-for="(film,index) in films" :key="index">
                        <div class="filmography_cell">
                            <div class="filmography_poster" @click="openLightbox(index)">
                                <img :src="film.poster">
                            </div>
                            <div class="filmography_textbox">
                                <div class="filmography_text">
                                    <h1 class="film_title">{{ film.title }}</h1>
                                    <h2 class="film_year">{{ film.year }}</h2>
                                    <p class="film_description">{{ film.description }}</p>
                                </div>
                            </div>
                            <div class="filmography_cell_gap"></div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
            <div class="lightbox" id="lightbox" v-if="lightboxVisible" @click.self="closeLightbox">
                <span class="close" @click="closeLightbox">&times;</span>
                <div class="lightbox_frame">
                    <img class="lightbox_content" :src="lightboxImg">
                    <div class="lightbox_text">
                        <h1>{{ lightboxTitle }}</h1>
                        <h2>{{ lightboxYear }}</h2>
                        <p>{{ lightboxDescription }}</p>
                    </div>
                </div>
            </div>

        </main>
</template>

<script>
export default{
    name: 'FilmographyMain',
    data(){
        return{
            films:[
                {
                    poster: require('@/assets/2a66afdb0d6945a3a70e4bcb9ffcdab6.jpeg'),
                    title: "Dust to Dust",
                    year: 2023,
                    description: "In 1995, a large-scale armed banknote robbery occurred in Guangdong, where a bank cash truck was robbed by five armed bandits, resulting in losses of up to 15 million yuan.",
                },
                {
                    poster: require("@/assets/ec646e0a-487f-46b1-9b24-ebb9bfa0e32c.jpg"),
                    title: "The Volunteers: To the War",
                    year: 2023,
                    description: "The Volunteers: To the War is a 2023 Chinese war film directed by Chen Kaige. Set during the Korean War, the film is intended to be the first installment of a planned trilogy from Chen depicting China's involvement in the war.",
                },
                {
                    poster: require("@/assets/8e9dc617257f484aa5d4b3531423c261.jpeg"),
                    title: "The Knockout",
                    year: 2023,
                    description: "The show focuses on the crime world of Jinghai and its collapse during the investigation.",
                },
                {
                    poster: require("@/assets/1711589741337717.jpg"),
                    title: "Life is A Long Quiet River",
                    year: 2022,
                    description: "It has been ten years since Feng Xiaoqin, a wife from another place, married to the Gu family with a big belly, while Gu Qingyu, the eldest sister-in-law, has always been wary of this woman who writes \"changing fate\" on her face. Feng Xiaoqin urged her husband Gu Lei to borrow money from her twin sister Gu Qingyu to buy a house, but was cleverly resolved by Gu Qingyu and revealed her plan to buy a luxury house. After Gu Lei's unexpected death, conflicts between his sister-in-law continued to escalate, and single mother Feng Xiaoqin was almost hopeless, but it also began the process of exploring her own value. She relied on her own wisdom and resilience to plan a nursing home \"not too late\" and make it bigger and stronger. Gu Qingyu reshaped his understanding of life in reality and dreams, from flash marriage to divorce, in order to fulfill his teenage dreams. Two women, from opposition to mutual understanding, will carry this family together no matter how stormy the future may be.",
                },
                {
                    poster: require("@/assets/badkids.jpg"),
                    title: "The Bad Kids",
                    year: 2020,
                    description: "The Bad Kids is a 2020 Chinese streaming television series that premiered on iQiyi on June 16, 2020, which tells a story of the web of consequences birthed among different families after three children accidentally film a murder. It is directed by Xin Shuang, and features an ensemble cast that includes Qin Hao, Wang Jingchun, Zhang Songwen, Liu Lin and Lu Fangsheng, with special starring by Rong Zishan, Shi Pengyuan and Wang Shengdi.",
                },
                {
                    poster: require("@/assets/kdent230816a.jpg"),
                    title: "No More Bets",
                    year: 2023,
                    description: "A Chinese programmer and a model who, enticed by the promise of high-paying jobs, find themselves trapped in a foreign country as prisoners of a scam mill and were forced to be part of their online fraud scheme.",
                },
                {
                    poster: require("@/assets/buzhibuxiu.jpg"),
                    title: "The Best Is Yet to Come",
                    year: 2020,
                    description: "The Best Is Yet to Come is a 2020 Chinese drama film directed by Wang Jing, in his feature film debut. The film is based on the real figure of journalist Han Fudong, who exposed the social stigma against hepatitis B suffering people in China. It premiered in the Horizons section of the 77th edition of the Venice Film Festival, and was later screened at the 2020 Toronto International Film Festival. The film was later released in China on March 24, 2023.",
                },
                {
                    poster: require("@/assets/wechatimg2970-1634788462.jpeg"),
                    title: "Saturday Fiction",
                    year: 2019,
                    description: "Saturday Fiction is a 2019 Chinese drama film directed by Lou Ye. Written by Yingli Ma and starring Gong Li, Mark Chao, and Joe Odagiri, it was selected to compete for the Golden Lion at the 76th Venice International Film Festival. The film had its premiere on 4 September 2019 at the festival. It was released theatrically on October 15, 2021 in China.",
                },
                {
                    poster: require("@/assets/1555381725_5287132.jpeg"),
                    title: "The Shadow Play",
                    year: 2019,
                    description: "The Shadow Play is a 2018 Chinese crime drama film directed by Lou Ye. It made its world premiere at the 58th Taipei Golden Horse Film Festival in 2018. The film was also shown in the Panorama section at the 69th Berlin International Film Festival. It was released theatrically on April 4, 2019 in China.",
                },
                {
                    poster: require("@/assets/e5c306ab1b0f41338ea5a6fa84ef2e1d.jpeg"),
                    title: "Red Amnesia",
                    year: 2014,
                    description: "Red Amnesia is a 2014 Chinese thriller film directed by Wang Xiaoshuai. The film deals with the psychological impact of an elderly retired widow (played by Lü Zhong)'s past. Wang considers Red Amnesia to be the last film of his Cultural Revolution trilogy, which includes 2005's Shanghai Dreams and 2011's 11 Flowers. The film was released on April 30, 2015.",
                },
                {
                    poster: require("@/assets/6b668ef395ef737f681b7ee96fc8bd07.jpg"),
                    title: "Spring Fever",
                    year: 2009,
                    description: "Spring Fever is a 2009 Chinese/French film directed by Lou Ye. The production of the film is in defiance of a five-year ban on filmmaking imposed by China's State Administration of Radio, Film, and Television (SARFT) for his previous film, Summer Palace. Filmed in Nanjing, the film was described to be about a young threesome overcome with erotic longings.\n\nBy the time of the film's premiere at the Cannes Festival on 13 May 2009, it was known that Lou had circumvented the five-year ban imposed upon him after Summer Palace by having Spring Fever registered as a Hong Kong/French co-production.",
                },
            ],
            lightboxVisible: false,
            lightboxImg: '',
            lightboxTitle: '',
            lightboxYear: '',
            lightboxDescription: '',
            originalOrder: [],
        };
    },
    mounted(){
        this.originalOrder = [...this.films];
    },
    methods:{
        openLightbox(index){
            const film = this.films[index];
            this.lightboxImg = film.poster;
            this.lightboxTitle = film.title;
            this.lightboxYear = film.year;
            this.lightboxDescription = film.description;
            this.lightboxVisible = true;
        },
        closeLightbox(){
            this.lightboxVisible = false;
        },
        handleSort(event){
            const sortBy = event.target.value;
            if(sortBy === 'default'){
                this.films = [...this.originalOrder];
            }else if(sortBy === 'alphabetical'){
                this.films.sort((a,b)=> a.title.localeCompare(b.title));
            }else if(sortBy === 'year'){
                this.film.sort((a,b) => b.year - a.year);
            }
        }
    }
};
</script>

<style scoped>

*{
    margin: 0;
    padding: 0;
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
}

/*--------------------------------------------------  main  ---------------------------------------------------------------------*/

main{
    background-color: #5E5E5E;
}

.film_sort{
    margin-left: 120px;
    margin-right: 132px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    text-align: right;
}

.film_sort select{
    color: white;
    background-color: #555;
    border-color: white;
    font-size: 14px;
}

@media (max-width:600px) {
    .film_sort{
        padding-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }
}

.filmography{
    margin-left: 120px;
    margin-right: 120px;  
}

@media (max-width:600px) {
    .filmography{
        margin-left: 20px;
        margin-right: 20px;
    }
}

.filmography .filmography_container{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    width: 100%;
}

@media (max-width:600px) {
    .filmography .filmography_container{
        display: block;
        width: 100%;
    }
}

.filmography .filmography_container .filmography_cell{
    display: grid;
    grid-template-columns: 49% 49% 2%;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media (max-width:600px) {
    .filmography .filmography_container .filmography_cell{
        margin-top: 0;
        margin-bottom:0;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.filmography_poster{
    height: 320px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.filmography_poster img{
    height: 100%;
    width: auto;
    object-fit: cover;
    transition: transform 0.3s ease, filter 0.3s ease;
}

.filmography_poster:hover img{
    cursor: pointer;
    transform: scale(1.1);
    filter: brightness(75%) grayscale(75%);
}

.filmography_textbox{
    height: 320px;
}

.filmography_text{
    margin-top: 50px;
    margin-left: 15px;
    margin-right: 15px;
}

.filmography .filmography_textbox{
    color: white;
    position: relative;
    border: 1px solid rgb(150, 150, 150);
    z-index: 1;
}

.filmography .filmography_textbox::before{
    content:"";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: url('@/assets/p288713428033.jpg');
    opacity: 0.1;
    background-size: cover;
    z-index: -1;
}

.film_description{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
}

h1{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 23px;
}

h2{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
}

p{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
}

/*--------------------------------------------------  lightbox  ---------------------------------------------------------------------*/

.lightbox {
    display: flex;
    position: fixed;
    z-index: 9999;
    padding-top: 60px;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.75);
    justify-content: center;
    align-items: center;
}

.lightbox_frame{
    display: grid;
    grid-template-columns: 50% 50%;
    height: 400px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 50px;
}

@media(max-width:600px) {
    .lightbox_frame{
        display: block;
        text-align: center;
        margin-left: 0;
    }
}

.lightbox_content {
    margin: auto;
    display: block;
    width: auto;
    height: 400px;
    overflow: hidden;
    
}

@media(max-width:600px) {
    .lightbox_content{
        text-align: center;
    }
}

.lightbox_text{
    color: white;
    width: 400px;
}

.close {
    position: absolute;
    top: 20px;
    left: 90vw;
    color: white;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}
</style>