<template>
    <footer>
        <div class="footer_navigation">
            <ul class="footer_navigation-list">
                <li class="navigation-item"><a href="https://www.facebook.com/">Facebook</a></li>
                <li class="navigation-item"><a href="https://www.instagram.com/">Instagram</a></li>
                <li class="navigation-item"><a href="https://x.com/">Twitter</a></li>
                <li class="navigation-item"><a href="https://weibo.com/">Weibo</a></li>
                <li class="navigation-item"><a href="https://www.xiaohongshu.com">Redbook</a></li>
            </ul>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'IndexFooter'
}

</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
}

footer{
    background-color: #5E5E5E;
    color: white;
    grid-row-gap: 0px;
}

.footer_navigation{
   padding-top: 10px;
   padding-bottom: 10px;
    margin-left: 80px;
    margin-right: 80px;
    border-top: 1px solid rgb(150, 150, 150);
}

.footer_navigation ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.footer_navigation li{
    margin-right: 50px;
    margin-left:50px;
}

footer ul li a{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    color:white;
    text-decoration: none;
}

@media (max-width:600px){
    .footer_navigation{
        margin-left: 15px;
        margin-right: 15px;
    }

    .footer_navigation li{
        margin-left: 10px;
        margin-right: 10px;
    }
    footer ul li a{
        font-size:11px;
    }
}
</style>