import { createRouter, createWebHistory } from 'vue-router';
import Main from '@/views/IndexMain.vue';
import Career from '@/views/CareerMain.vue';
import Gallery from '@/views/GalleryMain.vue';
import Filmography from '@/views/FilmographyMain.vue'
import Info from '@/views/InfoMain.vue'

const routes = [
    {
        path:'/',
        name:'Home',
        component: Main
    },
    {
        path:'/gallery',
        name:'Gallery',
        component: Gallery
    },
    {
        path:'/info',
        name:'Info',
        component: Info
    },
    {
        path:'/filmography',
        name:'Filmography',
        component: Filmography
    },
    {
        path:'/career',
        name:'Career',
        component: Career
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;