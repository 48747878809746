<template>
  <div id="app">
    <IndexHeader/>
    <NavigationBar/>
    <router-view/>
    <IndexFooter/>
  </div>

  <!--
  <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App Dichao Wang"/>
  -->
</template>

<script>

import IndexHeader from './components/IndexHeader.vue'
import NavigationBar from './components/NavigationBar.vue'
import IndexFooter from './components/IndexFooter.vue'

export default {
  name: 'App',
  components: {
    IndexHeader,
    NavigationBar,
    IndexFooter
  }
}
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
*/
</style>
