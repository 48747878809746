<template>
    <header>
            <div class="header_container_1">
                <div class="header_image_1">
                    <div class="header_slider">
                        <img src="@/assets/header_1.jpg" alt="Image_1">
                        <img src="@/assets/header_2.jpg" alt="Image_2">
                        <img src="@/assets/header_3.jpg" alt="Image_3">
                        <img src="@/assets/header_4.jpg" alt="Image_4">
                        <img src="@/assets/header_5.jpg" alt="Image_5">
                    </div>
                </div>

                <div class="header_image_2">
                    <img src="@/assets/header_6.jpg" alt="Image_6" >
                </div> 
                
                <div class="overlay"></div> 
            </div>

            <div class="header_topband">
                <div class="topband_content">
                    <div class="band_icon">
                        <a href="https://www.facebook.com" target="_blank" class="social_icon">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://www.twitter.com" target="_blank" class="social_icon">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com" target="_blank" class="social_icon">
                            <i class="fab fa-instagram"></i>
                        </a>
                        <a href="https://www.youtube.com" target="_blank" class="social_icon">
                            <i class="fab fa-youtube"></i>
                        </a>
                    </div>
                    <div class="band_title">
                        <div class="band_title_line"></div>
                        <h3 class="band_title_text">Zhang Songwen Global Fanpage</h3>
                        <div class="band_title_line"></div>
                    </div>
                    <div class="band_search">
                        <input type="text" placeholder="  search..." class="band_search_input">
                        <button class="band_search_button">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="header_title">
                <h2 class="header_title_text">&nbsp;&nbsp;The fiercer the storm <br>The bigger the fish</h2>
            </div>

        </header>
</template>

<script>
export default {
  name: 'IndexHeader'
}

document.addEventListener(
    'DOMContentLoaded', ()=>{
        const slides = document.querySelector('.header_slider');
        const images = document.querySelectorAll('.header_slider img');
        let index = 0 ;

        const getSlideWidth = () => {
            return images[index].clientWidth; 
        };
    
        const nextSlide = () => {
            const slideWidth = getSlideWidth(); 
            index++;
            if (index >= images.length) {
                index = 0;
            }
            slides.style.transform = `translateX(${-index * slideWidth}px)`;
        };
    
        setInterval(nextSlide, 3000);

        window.addEventListener('resize', () => {
            const slideWidth = getSlideWidth();
            slides.style.transform = `translateX(${-index*slideWidth}%)`;
        });
    });
</script>

<style scoped>

*{
    margin: 0;
    padding: 0;

}


body, html {
    margin: 0;
    padding: 0;
    width: 100%;
}

header{
    background-color: rgb(57, 57, 57);
    color: white;
}

.header_container_1{
    position: relative;
    display: grid;
    grid-template-columns: 3fr 1fr;
    height: 500px;
    width: 100%;
    overflow: hidden;
}
.header_container_1 .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: linear-gradient(to top, rgb(94, 94, 94, 100), rgb(94, 94, 94, 0));
  }
.header_image_1{
    position: relative;
    overflow: hidden;
}
.header_image_2{
    position: relative;
    overflow: hidden;
    text-align: center;
}
.header_slider{
    display: flex;
    width: 500%;
    height: 100%;
    transition: transform 1s ease-in-out;
}

.header_slider img{
    width: 100%;
    height: auto;
    object-fit:cover;
}

.header_image_2 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 600px) {
    .header_container_1{
        grid-template-columns: 1fr;
        height: auto;
        width: 100%;
    }
    .header_image_2{
        display: none;
    }
    .header_slider img{
        width: 20%;
        height: 100%;
        object-fit: cover;
    }
}

.header_topband{
    position:absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin-left: 120px;
    margin-right: 120px; 
}

@media (max-width:600px) {
    .header_topband{
        margin-left: 20px;
        margin-right: 20px;
    }
}

.topband_content{
    position: relative;
    height: 50px;
    display: grid;
    grid-template-columns: 20% 60% 20%; 
}

.band_icon{
    height: 50px; 
    display: flex;                 
    align-items: center; 
}

@media (max-width:600px) {
    .topband_content{
        max-width: 100%;
        height: 20px;
        grid-template-columns: 50% 50%;
    }
    .band_icon{
        height: 20px;
    }
}

.social_icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    text-align: center;
    margin: 0 10px;
    border-radius: 50%;
    background-color: #333;
    color: white;
    font-size: 18px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

@media (max-width:600px) {
    .social_icon{
        width: 15px;
        height: 15px;
        font-size: 12px;
        margin: 0 5px;
    }
}

.social_icon:hover {
    background-color: #555;
    transform: scale(1.1);
}

.social_icon i {
}

.band_title{
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    display: grid;
    grid-template-columns: 25% 50% 25%;
    align-items: center;
}

.band_title .band_title_text{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    color: white;
    text-decoration: none;
    text-align: center;
}

.band_title .band_title_line{
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px; 
    background-color: white;
}

@media (max-width:600px) {
    .band_title{
        position:absolute;
        top: 120px;
        right: 0px;
        width: 60px;
    }

    .band_title .band_title_text{
        text-align: right;
    }

    .band_title .band_title_line{
        display: none;
    }
}

.band_search{
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 70%;
}

.band_search_input{
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    border-radius:8px;
}

.band_search_button{
    margin-left: 5px;
    color: white;
    border: none;
    background-color:transparent;
    font-size: 16px;
}

@media (max-width:600px) {
    .band_search{
        display: flex;
        justify-content:flex-end;
        align-items: center;
        opacity: 70%;
    }

    .band_search_input{
        font-size: 10px;
        font-family: Arial, Helvetica, sans-serif;
        border: none;
        border-radius:8px;
        width: 120px;
    }

    .band_search_button{
        font-size: 12px;
    }
}

.header_title{
    position: absolute;
    top: 40%;
    left: 10%;
    transform: translate(-10%, -10%) rotate(-20deg);
}

.header_title_text{
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 56px;
    font-weight: 900;
    color: rgb(204, 153, 51, 0.75);
}

@media (max-width: 600px){
    .header_title{
        position: absolute;
        top: 15%;
        left: 8%;
        transform: translate(-10%, -10%) rotate(-20deg);
    }
    
    .header_title_text{
        font-family:Georgia, 'Times New Roman', Times, serif;
        font-size: 24px;
        font-weight: 900;
        color: rgb(204, 153, 51, 0.75);
    }
}

</style>