<template>
    <main>
            <div class="content_1">
                <div class="main_container_1">
                    <div class="poster">
                        <img src="@/assets/797.jpg">
                    </div>
                    <div class="content_gap"></div>
                    <div class="textbox">
                        <div class="textframe">
                            <h1>INFO</h1>
                            <p class="clamp_text">
                                Zhang Songwen is a Chinese actor and acting coach, best known for starring in the film The Shadow Play (2018), and the television series The Bad Kids (2020) and The Knockout (2023).<br><br>
                                Zhang Songwen was born in Shaoguan, Guangdong province. After high school, he held various jobs, including working at a printing factory, a food stall, and a beverage factory. From 1994 to 1999, he was employed as a tour guide with the China Travel Service. At the age of 24, Zhang first heard about Beijing Film Academy through a fellow tour guide. To pursue his dream of acting in films, he quit his job, moved to Beijing, and enrolled in the advanced vocational class of the Performance Department at Beijing Film Academy, where he served as class monitor and student union president. After graduating, he worked as a teaching assistant at the academy for eight years.</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </main>
</template>

<script>
export default{
    name: 'InfoMain'
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
}

main{
    background-color: #5E5E5E;
}

.content_1{
    margin-left: 120px;
    margin-right: 120px;  
}

@media (max-width:600px) {
    main{
        height: 800px;
    }

    .content_1{
        margin-left: 20px;
        margin-right: 20px;
    }
}

.main_container_1{
    display: grid;
    grid-template-columns: 40% 5% 50% 5%;
    height: 100%;
    width: 100%;
}

@media(max-width:600px){
    .main_container_1{
        padding-top: 20px;
        display: block;
    }
}

.content_1 img{
    height: 500px;
    width: auto;
    object-fit: cover;
}

@media(max-width:600px){
    .content_1 img{
        height: 300px;
    }
}

.content_1 .poster{
    margin-left: 150px;
    margin-right: 40px;
    overflow: hidden;
}

@media(max-width:600px){
    .content_1 .poster{
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
    }
}

.content_1 .textbox{
    position: relative;
    margin-left: 25px;
    margin-right: 25px;
    height: 320px;
    z-index: 1;
}

@media(max-width:600px){
    .content_1 .textbox{
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        height: auto;
    }
}

.content_1 .textbox .textframe {
    margin-left: 25px;
    margin-right: 25px;
    height: 50%;
    color: white;

}

@media(max-width:600px){
    .content_1 .textbox .textframe{
        margin-left: 0px;
        margin-right: 0px;
    }

    h1{
        text-align: center;
    }
}

h1{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;
}


p{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
}

</style>